@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700;900&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&display=swap');

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Cinzel Decorative', cursive;
  font-weight: 700;
  color: #19818f;
}

body {
  overflow-x: hidden;
}

button,
p {
  font-family: 'Poppins', sans-serif;
}

p {
  color: #444444;
  line-height: 31px;
  font-size: 18px;
}

a {
  text-decoration: none !important;
}

i {
  float: left;
  margin-top: 10px;
  color: #a58243;
}
button.btn.search:focus, button.btn.search:hover {
  color: #fff;
}

.top-bar a {
  color: #333;
  text-decoration: none;
}

/* Topbar css */
.top-bar {
  border-top: 3px solid #19818f;
  overflow: hidden;
}

nav .row {
  flex: 1;
}

.top-bar svg {
  fill: #19818f;
  margin-right: 8px;
}

.top-bar li:first-child {
  margin-right: 35px;
}

.navbar-collapse {
  flex-basis: auto;
}

.brand .img-responsive {
  max-height: 62px;
}

.navbar-nav {
  flex: 1;
  justify-content: space-between;
}

.navbar {
  background-color: #19818f;
  border-bottom: 2px solid #fff;
  box-shadow: 0px 3px 5px rgb(0 0 0 / 25%);
  overflow: hidden
}

.navbar a {
  color: #fff;
}

.search {
  background-color: #3aaaba;
  border: none;
  padding: 7px 20px;
  min-width: 214px;
  text-align: left;
  border-radius: 40px;
  color: #fff;
}

.navbar svg {
  fill: #fff;
  margin-right: 0;
  height: 15px;
}

.btn.search svg {
  margin-right: 10px;
  height: 13px;
}

.hamburger-toggle {
  display: flex;
}

.navbar-toggler:focus {
  box-shadow: none;
}

/* banner css */
.banner {
  background-image: url(./assets/banner-img.png);
  height: 84vh;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
  background-position: center;
}

.banner .heading {
  font-weight: 900;
  font-size: 3.5vw;
  color: #fff;
  text-shadow: 5px 5px 6px rgb(0 0 0 / 75%);
}

.tagline {
  font-size: 3.5vw;
  font-weight: normal;
  color: #fff;
  text-shadow: 5px 4px 4px rgb(0 0 0 / 75%);
}

.banner p {
  color: #fff;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  font-weight: normal;
  text-shadow: 5px 1px 4px rgb(0 0 0 / 75%);
}

/* time-machine css */
.time-machine-wrap.text-center {
  padding: 50px 15px;
  box-shadow: 0px 0px 10px 3px rgb(0 0 0 / 17%);
  margin: -100px auto 0 auto;
  background-color: #fff;
  width: 90%;
}

.radio-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-wrap input {
  margin-right: 7px;
}

.gradient-button {
  font-size: 18px;
  text-transform: capitalize;
  color: #fff;
  border: none;
  background: linear-gradient(0deg, rgba(30, 134, 148, 1) 1%, rgba(55, 166, 181, 1) 100%);
  padding: 10px 41px;
  border-radius: 50px;
}

.p-80 {
  padding: 80px 0;
}

/* about-author-css */

.author {
  color: #111;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  border-bottom: 1px solid #111;
  display: inline-block;
  margin-bottom: 20px;
  position: relative;
  overflow:hidden;
}

.rect {
  position: relative;
}

.rect:before {
  position: absolute;
  content: '';
  width: 58px;
  height: 66px;
  background-color: transparent;
  border: 1px solid #19818f;
  left: -7px;
  top: -5px;
  animation: pulse 2s infinite;
}

.heading-text.first-text {
  text-transform: uppercase;
}

.second-text {
  margin-top: -7px;
  margin-left: 48px;
}

.heading-text {
  font-size: 53px;
}

/* Our-future-css */
.our-future.p-80 {
  background-image: url(../src/assets/Future-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}

.future-wrap {
  display: flex;
  background-color: #fff;
  box-shadow: 0px 0px 10px 4px rgb(25 129 143 / 17%);
  padding: 50px;
}

.future-wrap .img-fluid {
  max-width: 90%;
  margin: auto;
}

/* Footer */
.widget-title {
  color: #333;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.site-social-menu {
  margin-bottom: 40px;
}

footer .menu-item a {
  color: #404040;
  font-size: 14px;
  line-height: 29px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.has-post-thumbnail {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.postlist-title {
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-bottom: 0;
  line-height: 20px;
}

.postlist-title a {
  color: #404040;
}

.postlist-date {
  font-size: 13px;
  opacity: 0.6;
  font-family: 'Poppins', sans-serif;
}

footer.p-80 {
  background-image: url(../src/assets/footer-pattern.png );
  background-size: contain;
  background-repeat: repeat;
}

.wp-post-image {
  max-width: 71px;
  margin-right: 15px;
}

.menu-item img {
  max-width: 48px;
  margin-right: 20px;
}

#subscribe-blog {
  justify-content: center;
  background-color: #fff;
  padding: 15px;
  border-radius: 130px;
  align-items: center;
}

#subscribe-email {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0;
  padding: 0 15px;
}

#subscribe-field {
  width: 100%;
  border: none;
  font-size: 12px;
}

#subscribe-blog label {
  color: #19818f;
  font-size: 24px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  line-height: 24px;
}

#subscribe-blog {
  justify-content: center;
}

.copyright p {
  margin: 0;
  padding: 15px 0;
  font-size: 14px;
}

@media screen and (max-width:1366px) {
  .banner p {
    font-size: 17px;
  }

  .heading-text {
    font-size: 45px;
  }

  .rect:before {
    width: 53px;
    height: 57px;
  }
}

@media screen and (min-width:992px) {
  button.navbar-toggle {
    display: none;
  }

  .navbar-collapse {
    display: flex !important;
  }
}

@media screen and (max-width:991px) {
  .banner {
    background-position-x: center;
  }

  .time-machine-wrap.text-center {
    width: 100%;
  }

  #footer-sidebar-second,
  #footer-sidebar-third {
    margin-top: 25px;
  }

  button.navbar-toggler {
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    outline: none;
    box-shadow: none;
    position: relative;
  }

  .nav.navbar-nav.flex-row {
    flex-direction: column !important;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .dropdown-menu .show {
    -webkit-animation: fadeIn 0.3s alternate;
    /* Safari 4.0 - 8.0 */
    animation: fadeIn 0.3s alternate;
  }

  .nav-item.dropdown.dropdown-mega {
    position: static;
  }

  .nav-item.dropdown.dropdown-mega .dropdown-menu {
    width: 90%;
    top: auto;
    left: 5%;
  }

  .navbar-toggler {
    border: none;
    padding: 0;
    outline: none;
  }

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar .container {
    position: relative;
  }

  #navbar-content {
    position: absolute;
    background-color: #4cb9c9;
    left: 15px;
    width: 95%;
    z-index: 9;
    padding: 0 15px;
    box-shadow: 1px 6px 9px rgb(0 0 0 / 25%);
    top: 100%;
  }
}

@media screen and (max-width:767px) {
  .banner {
    height: 50vh;
  }

  .time-machine-wrap.text-center {
    margin: 60px auto 0 auto;
  }

  .p-80 {
    padding: 60px 0;
  }

  .future-wrap {
    flex-direction: column;
  }

  .future-wrap .img-fluid {
    max-width: 90%;
  }

  #subscribe-blog label {
    color: #19818f;
    font-size: 16px;
  }

  .rect:before {
    width: 43px;
    height: 47px;
  }

  .heading-text {
    font-size: 35px;
  }
}

@media screen and (max-width:576px) {
  .top-bar .d-flex.align-items-center {
    text-align: center;
  }

  ul.header-info {
    justify-content: space-evenly !important;
    margin-bottom: 10px !important;
  }
}


/* .banner .heading {
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
             
}
.banner .heading {
white-space: nowrap;
  overflow: hidden;
}

/* text animation */

/* @keyframes animated-text{
  from{width: 0;}
  to{width: 500px;}
}

/* cursor animations */

/* @keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
} */
